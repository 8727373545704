import React from 'react'

import SEO from '../components/seo/seo'
import MainWrapper from '../components/ui/MainWrapper'
import 'url-search-params-polyfill';
import queryString from 'query-string'
import { Location } from "@reach/router"
import Loading from '../components/ui/Loading';

const MeasurementsPage = () => {
	const [id, setId] = React.useState<null | string>(null)
	React.useEffect(() => {
		if (typeof window !== 'undefined') {
			const queryStrings = queryString.parse(location.search);
			setId(queryStrings.id as string)
		}
	})

	if (!id) {
		return <Loading />
	}

	return (
		<MainWrapper>
			<SEO title="Home Measurements" keywords={['measurements', '']} />
			<iframe
				style={{ width: "100%", height: "100vh" }}
				src={`https://fairmarket.renoworks.com/pages/project?project=${id}`}
			/>
		</MainWrapper>
	)
}

export default MeasurementsPage
